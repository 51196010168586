<template>
    <Modal ref="accountModal" :value="show" width="40%" @on-cancel="oncancel" :mask-closable=false @on-visible-change="onvisit">
        <p slot="header" style="color:#f60;text-align:left">
            <span v-if="partcerinfoid>=0">零部件证书信息修改</span>
            <span v-else>零部件证书信息添加</span>
        </p>
        <Form ref="PartCerInfoForm" :model="formInline" :label-width="160" label-position="right" :rules="ruleInline">
            <Row  >
              <i-col span="24">
                <FormItem prop="product" label="部件:">
                    <i-input type="text" v-model="formInline.product" :maxlength="20" show-word-limit></i-input>
                </FormItem>
              </i-col>
            </Row>
            <Row>                
              <i-col span="24">
                <FormItem  prop="cccno" label="CCC/自愿性证书编号:">
                    <i-input  type="text" v-model="formInline.cccno"  :maxlength="20" show-word-limit></i-input>
                </FormItem>
              </i-col>
            </Row>
            <Row>  
              <i-col span="24">  
                <FormItem prop="effect" label="有效性">
                        <i-input  type="text" v-model="formInline.effect"  :maxlength="20" show-word-limit></i-input>
                </FormItem>                  
              </i-col>
            </Row>
            <Row>  
                <i-col span="24">  
                  <FormItem prop="note" label="备注">
                      <i-input type="text" v-model="formInline.note" :maxlength="20" show-word-limit></i-input>
                  </FormItem>                  
                </i-col>
              </Row>
        </Form>
        <div slot="footer">
            <Button type="error" size="large" long  @click="handleSubmit('PartCerInfoForm')">提交</Button>
        </div>
    </Modal>
</template>
<script>
    export default {
        name:'recharge',
        props:['show','partcerinfoid','product','cccno','effect','note'],
        data () {
            return {
                companyid:this._self.$root.$store.state.LogonInfo.companyid,
                userid: this._self.$root.$store.state.LogonInfo.userid,
                formInline: {
                    cccno: this.cccno,
                    effect:this.effect,
                    product: this.product,
                    note:this.note,
                    partcerinfoid:this.partcerinfoid
                },
                ruleInline: {
                    cccno: [
                        { required: true, message: '请输入CCC/自愿性证书编号', trigger: 'blur' },
                    ],
                    product: [
                        { required: true, message: '请输入部件名称', trigger: 'blur' },
                    ],
                },
                departmentList:null
            }
        },
        mounted (){
            
        },
        methods: {
            handleSubmit (name) {
                this.$refs[name].validate((valid) =>{
                    if(valid){
                        var posturl=''
                        var postdata=JSON.parse(JSON.stringify(this.formInline)) 

                        if(this.partcerinfoid >=0){
                            posturl = "/api/checkdept/purchase/partcerinfo/update"  
                            postdata.id=this.partcerinfoid                        
                        }
                        else{
                            posturl ="/api/checkdept/purchase/partcerinfo/addnew"
                        }

                        for(var p in postdata){
                            if(postdata[p] == null){
                                delete postdata[p]
                            }
                        }

                        postdata.createuser = this.userid
                        postdata.companyid = this.companyid

                        this.$axios({
                            method:'post',
                            url:posturl,
                            data:postdata,
                            headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                            transformRequest: function(obj) {
                                var str = [];
                                for(var p in obj){
                                    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                                }
                                return str.join("&");
                            }
                        }).then(function(res){
                            if(res.data.resultCode ==0){
                                this.$Message.success({content:res.data.msg, duration:3})

                                this.formInline.cccno=''
                                this.formInline.product = ''
                                this.formInline.note=''
                                this.formInline.effect=''
                                this.$refs['accountModal'].close()
                            }
                            else{
                                this.$Message.error({content:res.data.msg, duration:3})
                            }
                        }.bind(this));   
                    }
                    else{
                        this.$Message.error("请输入正确的内容")
                    }
                })
            },
            oncancel(){
                this.$emit('ClosePartcerinfoedit','')
            },
            onvisit(visit){
                if(visit){
                    this.formInline.product = this.product
                    this.formInline.effect = this.effect
                    this.formInline.cccno = this.cccno
                    this.formInline.partcerinfoid = this.partcerinfoid
                    this.formInline.note = this.note
                }
            }
        }
    }
</script>
