<!-- 合格证台账打印功能-->
<template>
<div style="margin: 5 auto;">
    <div style="margin:0 auto;float:right">
        <Button type="warning" style="margin-right: 50px;" @click="previewdata">打印预览</Button>
        <Button type="primary"  @click="printdata">直接打印</Button>    
    </div>
    
    <div id="printdiv" style="margin:0 auto;margin-top:1px;width:1300px;height:920px;clear: both;">
        <table  style="width: 100%;height:5%;word-wrap: break-word; word-break: break-all;" >
            <tr>
                <td width="25%">
                </td>
                <td width="50%" style="text-align: center;">
                    <h2>关键零部件和原材料证书有效性查询</h2>
                </td>
                <td width="25%">
                    {{modelcfg.companycode}}-CX-011-06
                </td>
            </tr>
        </table>
        <table id="tbl"  border="1px solid black" cellspacing="0"  style="margin:0 auto;width:100%;height:90%"  >
            <tr style="height: 30px;font-size: 16px;font-weight: bold;font-family: '楷体';padding-left: 4px;">
                <th align="left">序号</th>
                <th align="center" valign="center">部件</th>
                <th align="center" valign="center">CCC/自愿性证书编号</th>
                <th align="center" valign="center">有效性</th>
                <th align="center" valign="center">备注</th>
            </tr>
            <tr v-for="(item,index) in partcerinfo"  style="height: 20px;font-size: 12px;font-family: '楷体';padding-left: 4px;">
                <td>{{index}}</td>
                <td>{{item.product}}</td>
                <td>{{item.cccno}}</td>
                <td>{{item.effect}}</td>
                <td>{{item.note}}</td>
            </tr>
            <tr v-for="index in (28-partcerinfo.length)" style="height: 20px;font-size: 12px;font-family: '楷体';padding-left: 4px;">
                <td ></td>
                <td ></td>
                <td ></td>
                <td ></td>
                <td ></td>
            </tr>
        </table>
        <table  style="width: 100%;height: 5%;margin-top: 8px;word-wrap: break-word; word-break: break-all;" >
            <tr style="text-align: left;">
                <td width="25%">
                    查询人:
                </td>
                <td width="25%" >

                </td>
                <td width="25%">

                </td>
                <td width="25%">
                    日期:
                </td>
            </tr>
        </table>
    </div>
</div>
</template>
 <script>
    import {getPrinters,public_print,public_print_with_dir,public_preview_with_dir} from '@/zsjs/zskjprinter.js'
    import * as sysTool from '@/zsjs/systemTool.js'
    export default {
        name:'PartCerInfoPrint',
        props:['partcerinfo'],
        data(){
            return {
                modelcfg:this._self.$root.$store.state.ModelCfg
            }
        },
        mounted(){
            console.log(this.partcerinfo)
            console.log(this.modelno)
        },
        methods:{
            getpreDate(maodate){
                return sysTool.getNextDate(maodate,-1)
            },
            formatDate(date){
                if(date !=null)
                    return  (new Date(date)).Format('yyyy-MM-dd')
                else
                    return ''    
            },
            previewdata(){
                public_preview_with_dir("printdiv",1,2);
            },
            printdata(){
                public_print_with_dir("printdiv",1,2);
            },
        },
        mounted(){
        },
        watch:{
            modelno(n){
                console.log(this.modelno)
            },
            partcerinfo (n){
                console.log(this.partcerinfo)
            },
            modelcfg (n){
                console.log(this.modelcfg)
            }
        }
    }
</script>

<style type="text/css">
.printtd{
    padding-top:5px;
    padding-bottom:5px;
    height:40px;
}

#tbl {
    
}

#tbl  th {
    font-size: 16px;
    font-weight: bold;
}
#tbl  td{
    font-size: 12px;
    font-family: "楷体";
    padding-left: 4px;
}
</style>