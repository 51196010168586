<template>
<div >
        <Modal v-model="showprint" title="查看关键零部件和原材料证书有效性查询" width="1350px" scrollable>
            <partcerinfoprint  v-bind:partcerinfo="this.tbldata"  />
        </Modal>
        
    <div style="height:40px;width:100%;">
        <h2>关键零部件和原材料证书有效性查询</h2>
    </div>
    
    <!-- 部门账号列表-->
    <div>
        <div style="height:40px;width:100%;">
            <Button v-if="usercategory != 'third'" type="warning" icon="ios-add-circle-outline" style="margin-top:6px;float:left" @click="addPartCerInfo">添加数据</Button>
            <Button type="primary" v-if="tbldata.length>0"  style="margin-top:3px;margin-left: 10px;float:right" @click="showprint=true">打印</Button>
        </div>        
        <Table border :columns="tblcolumns" stripe :data="tbldata" size="small">
        <template slot-scope="{ row }" slot="product">
            <strong>{{ row.product }}</strong>
        </template>
        <template slot-scope="{ row,index }" slot="files">
            <Row>
                <i-col span="12" gutter="2">
                    <div class="opbar" style="background: rgb(247, 242, 236);">
                        <Button :disabled="row.cerpath==null" type="success" size="small" ghost @click="viewfile(row.cerpath)" >查看证书</Button>
                        <Upload
                            v-if="usercategory=='slave'"
                            action="/api/checkdept/purchase/partcerinfo/files/upload"
                            :data= "{
                                companyid: row.companyid,
                                id: row.id,
                                desc: 'cerpath'
                            }"
                            :on-success="handleSuccess"
                            :show-upload-list=false
                            >
                            <Button v-if="row.cerpath==null" type="error" size="small" ghost >上传证书</Button>
                            <Button v-else type="error" size="small" ghost >重新上传</Button>
                        </Upload>
                    </div>
                </i-col>
                <i-col span="12">
                    <div class="opbar" style="background: rgb(231, 248, 248);">
                        <Button :disabled="row.imgpath==null" type="success" size="small" ghost @click="viewfile(row.imgpath)" >查看截图</Button>
                        <Upload
                            v-if="usercategory=='slave'"
                            action="/api/checkdept/purchase/partcerinfo/files/upload"
                            :data= "{
                                companyid: row.companyid,
                                id: row.id,
                                desc: 'imgpath'
                            }"
                            :on-success="handleSuccess"
                            :show-upload-list=false
                            >
                            <Button v-if="row.imgpath==null" type="error" size="small" ghost >上传截图</Button>
                            <Button v-else type="error" size="small" ghost >重新上传</Button>
                        </Upload>
                    </div>
                </i-col>
            </Row>
        </template>
        <template slot-scope="{ row, index }" slot="action">
            <Button type="primary" size="small" style="margin-right: 5px" @click="modify(index)">修改</Button>
            <Button type="error" size="small" @click="remove(index)">删除</Button>
        </template>
        </Table>
        <partcerinfoedit v-bind:show="showPartcerinfoedit" v-bind:partcerinfoid="editPartcerinfoId"  v-bind:product="editProduct" v-bind:cccno="editCccno" v-bind:effect="editEffect" v-bind:note="editNote" @ClosePartcerinfoedit="onPartcerinfoeditClose"/>
        <Modal v-model="showDeleteDialog" title="确定要删除吗?" @on-ok="confirmDelete">
        </Modal>
    </div>

    </div>
</template>

<script>
import PartCerInfoEdit from './PartCerInfoEdit'
import PartCerInfoPrint from '@/views/business/workshop/checkdept/partcerinfo/PartCerInfoPrint'
export default {
    name:'PartCerInfo',
    data () {
        return {
            showPartcerinfoedit:false,
            editAccountId: this._self.$root.$store.state.LogonInfo.companyid,
            usercategory: this._self.$root.$store.state.LogonInfo.userCategory,
            editPartcerinfoId:null,
            editProduct:null,
            editCccno:null,
            editEffect:null,
            editNote:null,
            showDeleteDialog:false,
            showprint:false,
            companyname:this._self.$root.$store.state.LogonInfo.companyname,
            tblcolumns: [
                {
                    type: 'index',
                    width: 60,
                    align: 'center'
                },
                {
                    title: '部件',
                    slot: 'product',
                    width:100,
                    resizable:true
                },
                {
                    title: 'CCC/自愿性证书编号',
                    key: 'cccno',    
                    width:200,
                    resizable:true     
                },
                {
                    title: '有效性',
                    key: 'effect',
                    width:100,
                    resizable:true
                },
                {
                    title: '备注',
                    key: 'note',
                    width:120,
                    resizable:true
                },
                {
                    title:'证书及有效性截图',
                    slot:'files'
                },
                {
                    title: '操作',
                    slot: 'action',
                    fixed:'right',
                    align: 'center',
                    width:200,
                    resizable:true
                }
                
            ],
            tbldata: []
        }
    },
    mounted (){
        this.editAccountId =this._self.$root.$store.state.LogonInfo.companyid
        this.refreshpartcerinfo()
    },
    methods: {
        addPartCerInfo(){
            this.editPartcerinfoId = -1
            this.showPartcerinfoedit = true
            this.editProduct =''
            this.editCccno =''
            this.editEffect = ''
            this.editNote = ''
        },
        modify (index) {
            this.editPartcerinfoId = this.tbldata[index].id
            this.editProduct =this.tbldata[index].product
            this.editCccno =this.tbldata[index].cccno
            this.editEffect = this.tbldata[index].effect
            this.editNote = this.tbldata[index].note
            this.showPartcerinfoedit = true
        },
        remove (index) {
            //this.tbldata.splice(index, 1);
            this.editPartcerinfoId = this.tbldata[index].id
            this.editProduct =this.tbldata[index].product
            this.showDeleteDialog = true
        },
        viewfile (url){
            let openurl = window.location.protocol+"//"+window.location.host+url
            console.log(openurl)
            window.open(openurl, '_blank');
        },
        confirmDelete(){
            this.$axios({
                method:'post',
                url:"/api/checkdept/purchase/partcerinfo/delete",
                data:{
                    id:this.editPartcerinfoId,
                },
                headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                transformRequest: function(obj) {
                    var str = [];
                    for(var p in obj){
                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                    }
                    return str.join("&");
                }
            }).then(function(res){
                if(res.data.resultCode ==0){
                    this.$Message.success({content:res.data.msg, duration:3})
                    this.refreshpartcerinfo()
                }
                else{
                    this.$Message.error({content:res.data.msg, duration:3})
                }
            }.bind(this));               
        },
        onPartcerinfoeditClose (){
            this.showPartcerinfoedit = false
            this.refreshpartcerinfo()
        },
        refreshpartcerinfo(){
            console.log(this.editAccountId)
            console.log(this._self.$root.$store.state.LogonInfo.companyid)
            this.$axios({
                method:'post',
                url:"/api/checkdept/purchase/partcerinfo/list",
                data:{
                    companyid:this.editAccountId
                },
                headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                transformRequest: function(obj) {
                    var str = [];
                    for(var p in obj){
                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                    }
                    return str.join("&");
                }
            }).then(function(res){
                console.log(res.data)
                if(res.data.resultCode ==0){
                    this.tbldata = res.data.data
                }
                else{
                    this.$Message.error({content:res.data.msg, duration:3})
                }
            }.bind(this));   
        },
        onpagechange(num){
            this.tblpageinfo.currentpage = num
            this.refreshpartcerinfo()
        },
        handleSuccess(response, file, fileList){
            this.$Message.success({content:"文件上传成功", duration:3})
            this.refreshpartcerinfo()
        },
    },
    components:{
        'partcerinfoedit': PartCerInfoEdit,
        'partcerinfoprint':PartCerInfoPrint
    },
    
}
</script>
<style>
    .opbar {
        display: inline-flex;
        align-items: center;
        padding: 5px;
    }

    .opbar button{
        margin:0px 4px;
    }
</style>