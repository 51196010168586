var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Modal',{attrs:{"title":"查看关键零部件和原材料证书有效性查询","width":"1350px","scrollable":""},model:{value:(_vm.showprint),callback:function ($$v) {_vm.showprint=$$v},expression:"showprint"}},[_c('partcerinfoprint',{attrs:{"partcerinfo":this.tbldata}})],1),_vm._m(0),_c('div',[_c('div',{staticStyle:{"height":"40px","width":"100%"}},[(_vm.usercategory != 'third')?_c('Button',{staticStyle:{"margin-top":"6px","float":"left"},attrs:{"type":"warning","icon":"ios-add-circle-outline"},on:{"click":_vm.addPartCerInfo}},[_vm._v("添加数据")]):_vm._e(),(_vm.tbldata.length>0)?_c('Button',{staticStyle:{"margin-top":"3px","margin-left":"10px","float":"right"},attrs:{"type":"primary"},on:{"click":function($event){_vm.showprint=true}}},[_vm._v("打印")]):_vm._e()],1),_c('Table',{attrs:{"border":"","columns":_vm.tblcolumns,"stripe":"","data":_vm.tbldata,"size":"small"},scopedSlots:_vm._u([{key:"product",fn:function({ row }){return [_c('strong',[_vm._v(_vm._s(row.product))])]}},{key:"files",fn:function({ row,index }){return [_c('Row',[_c('i-col',{attrs:{"span":"12","gutter":"2"}},[_c('div',{staticClass:"opbar",staticStyle:{"background":"rgb(247, 242, 236)"}},[_c('Button',{attrs:{"disabled":row.cerpath==null,"type":"success","size":"small","ghost":""},on:{"click":function($event){return _vm.viewfile(row.cerpath)}}},[_vm._v("查看证书")]),(_vm.usercategory=='slave')?_c('Upload',{attrs:{"action":"/api/checkdept/purchase/partcerinfo/files/upload","data":{
                                companyid: row.companyid,
                                id: row.id,
                                desc: 'cerpath'
                            },"on-success":_vm.handleSuccess,"show-upload-list":false}},[(row.cerpath==null)?_c('Button',{attrs:{"type":"error","size":"small","ghost":""}},[_vm._v("上传证书")]):_c('Button',{attrs:{"type":"error","size":"small","ghost":""}},[_vm._v("重新上传")])],1):_vm._e()],1)]),_c('i-col',{attrs:{"span":"12"}},[_c('div',{staticClass:"opbar",staticStyle:{"background":"rgb(231, 248, 248)"}},[_c('Button',{attrs:{"disabled":row.imgpath==null,"type":"success","size":"small","ghost":""},on:{"click":function($event){return _vm.viewfile(row.imgpath)}}},[_vm._v("查看截图")]),(_vm.usercategory=='slave')?_c('Upload',{attrs:{"action":"/api/checkdept/purchase/partcerinfo/files/upload","data":{
                                companyid: row.companyid,
                                id: row.id,
                                desc: 'imgpath'
                            },"on-success":_vm.handleSuccess,"show-upload-list":false}},[(row.imgpath==null)?_c('Button',{attrs:{"type":"error","size":"small","ghost":""}},[_vm._v("上传截图")]):_c('Button',{attrs:{"type":"error","size":"small","ghost":""}},[_vm._v("重新上传")])],1):_vm._e()],1)])],1)]}},{key:"action",fn:function({ row, index }){return [_c('Button',{staticStyle:{"margin-right":"5px"},attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.modify(index)}}},[_vm._v("修改")]),_c('Button',{attrs:{"type":"error","size":"small"},on:{"click":function($event){return _vm.remove(index)}}},[_vm._v("删除")])]}}])}),_c('partcerinfoedit',{attrs:{"show":_vm.showPartcerinfoedit,"partcerinfoid":_vm.editPartcerinfoId,"product":_vm.editProduct,"cccno":_vm.editCccno,"effect":_vm.editEffect,"note":_vm.editNote},on:{"ClosePartcerinfoedit":_vm.onPartcerinfoeditClose}}),_c('Modal',{attrs:{"title":"确定要删除吗?"},on:{"on-ok":_vm.confirmDelete},model:{value:(_vm.showDeleteDialog),callback:function ($$v) {_vm.showDeleteDialog=$$v},expression:"showDeleteDialog"}})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"40px","width":"100%"}},[_c('h2',[_vm._v("关键零部件和原材料证书有效性查询")])])
}]

export { render, staticRenderFns }